<template>
  <form-layout
    @submit="updateProfile"
    :is-loading="state.isInitialLoading"
    title="Profile Information"
    icon="account_circle"
    description="Update your account's profile information"
  >
    <errors :errors="state.errors" class="mb-4" />

    <div class="w-full md:w-1/2 flex flex-col space-y-2">
      <text-input label="Display name" v-model="state.form.username" :disabled="state.isLoading" />
    </div>

    <template #actions>
      <submit-button :is-loading="state.isLoading">Save</submit-button>
    </template>
  </form-layout>
</template>

<script>
import { reactive } from 'vue'
import http from '@/services/http.js'
import notification from '@/services/notifications.js'
import FormLayout from '@/components/form/Layout.vue'
import TextInput from '@/components/form/Text.vue'
import Errors from '@/components/form/Errors.vue'
import SubmitButton from '@/components/form/SubmitButton.vue'

export default {
  name: 'profile-change-password',

  components: {
    TextInput,
    SubmitButton,
    Errors,
    FormLayout,
  },

  setup() {
    const state = reactive({
      form: {},
      errors: [],
      isLoading: false,
      isInitialLoading: true,
    })

    const getProfile = async () => {
      const { data } = await http.get('user/profile')

      state.form = data
      state.isInitialLoading = false
    }

    getProfile()

    const updateProfile = async () => {
      state.errors = []

      if (!state.form.username || state.form.username?.trim() === '') {
        state.errors.push('The Username field is required.')
      }

      if (state.form.username?.length < 3) {
        state.errors.push('The Username must be at least 3 characters.')
      }

      if (state.errors.length > 0) return

      state.isLoading = true

      const result = await http.post('user/profile', state.form)

      if (result) {
        state.errors = []
        notification.success('Great!', 'Your profile has been saved')
      } else {
        state.errors.push('Error occured. Please try again.')
      }

      state.isLoading = false
    }

    return {
      updateProfile,
      state,
    }
  }
}
</script>
