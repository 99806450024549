<template>
  <layout>
    <template #secondarySidebar>
      <settings-nav />
    </template>

    <div class="max-w-5xl mx-auto xl:mt-4">
      <h2 class="font-bold text-xl">My Profile</h2>

      <div class="space-y-6 xl:space-y-8 mt-4 xl:mt-6 mb-24">
        <update-username-form />

        <change-password />

        <alerts-form />
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from '@/layouts/Default.vue'
import ChangePassword from '@/components/profile/ChangePassword.vue'
import UpdateUsernameForm from '@/components/profile/UpdateUsernameForm.vue'
import AlertsForm from '@/components/profile/AlertsForm.vue'
import SettingsNav from '@/components/settings/Nav'

export default {
  name: 'profile',

  components: {
    Layout,
    ChangePassword,
    UpdateUsernameForm,
    AlertsForm,
    SettingsNav,
  },

  setup() {
    //
  }
}
</script>
