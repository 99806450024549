<template>
  <form-layout
    @submit="changePassword"
    title="Update Password"
    icon="lock"
    description="Ensure your account is using a long, random password to stay secure"
  >
    <errors :errors="state.errors" class="mb-4" />

    <div class="w-full md:w-1/2 flex flex-col space-y-2">
      <text-input label="Current password" type="password" v-model="state.form.currentPassword" />
      <text-input label="New password" type="password" v-model="state.form.newPassword" />
      <text-input label="Confirm Password" type="password" v-model="state.form.confirmPassword" />
    </div>

    <template #actions>
      <submit-button :is-loading="state.isLoading">Save</submit-button>
    </template>
  </form-layout>
</template>

<script>
import { reactive } from 'vue'
import { Auth } from '@/services/auth.js'
import notification from '@/services/notifications.js'
import FormLayout from '@/components/form/Layout.vue'
import TextInput from '@/components/form/Text.vue'
import Errors from '@/components/form/Errors.vue'
import SubmitButton from '@/components/form/SubmitButton.vue'

export default {
  name: 'profile-change-password',

  components: {
    FormLayout,
    TextInput,
    SubmitButton,
    Errors,
  },

  setup() {
    const initialFormData = {
      newPassword: '',
      currentPassword: '',
      confirmPassword: '',
    }
    const state = reactive({
      form: JSON.parse(JSON.stringify(initialFormData)),
      errors: [],
      isLoading: false,
    })

    const changePassword = async () => {
      state.errors = []

      if (state.form.currentPassword.trim() === '') {
        state.errors.push('The current password field is required.')
      }

      if (state.form.newPassword.trim() === '') {
        state.errors.push('The new password field is required.')
      }

      if (state.form.confirmPassword.trim() === '') {
        state.errors.push('The confirm password field is required.')
      }

      if (state.form.newPassword.length < 8) {
        state.errors.push('The password must be at least 8 characters.')
      }

      if (state.form.newPassword !== state.form.confirmPassword) {
        state.errors.push('The password confirmation does not match.')
      }

      if (state.errors.length > 0) return

      state.isLoading = true

      const result = await Auth.changePassword(state.form.currentPassword, state.form.newPassword)

      if (result) {
        state.form = JSON.parse(JSON.stringify(initialFormData))
        state.errors = []
        notification.success('Great!', 'Your password has been changed')
      } else {
        state.errors.push('The provided password does not match your current password.')
      }

      state.isLoading = false
    }

    return {
      changePassword,
      state,
    }
  }
}
</script>
