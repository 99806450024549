<template>
  <form-layout
    @submit="save"
    :is-loading="state.isInitialLoading"
    title="User Notifications"
    icon="circle_notifications"
    description="Configure machine notifications by your preference"
  >
    <errors :errors="state.errors" />

    <div class="flex flex-col space-y-6">
      <div class="flex items-center justify-between px-2">
        <div class="flex flex-col">
          <p class="font-semibold text-big-stone" id="privacy-option-1-label" :class="{ 'font-bold': state.form.enabled, 'font-semibold': !state.form.enabled }">
            Enable user notifications
          </p>
        </div>
        <div class="flex justify-end">
          <toggle v-model="state.form.enabled" @change="save('Alerts', 'Alerts', state.form.enabled)" />
        </div>
      </div>

      <transition
        enter-active-class="transition ease-out duration-200"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
      >
        <div v-show="state.form.enabled">
          <div class="flex flex-col lg:flex-row lg:space-x-4 xl:space-x-6 border-t border-gray-100">
            <div class="w-full lg:w-5/12 pt-4 xl:pt-6">

              <div class="rounded-md bg-gray-50 flex-col space-y-4 xl:space-y-6 p-2 md:p-4">
                <div v-if="state.form.sms_enabled" class="flex items-center justify-between">
                  <div class="flex flex-col">
                    <p class="text-big-stone" id="privacy-option-1-label" :class="{ 'font-semibold': state.form.notify_via_sms, 'font-regular': !state.form.notify_via_sms }">
                      Notify via SMS
                    </p>
                    <text-input v-if="!state.form.notify_via_sms" class="mt-1" v-model="state.newPhoneNumber" placeholder="Phone Number" />
                    <div v-else>{{ state.initialData.phone_number }}</div>
                  </div>
                  <div class="flex justify-end">
                    <toggle v-model="state.form.notify_via_sms" @change="save('Via SMS', 'Notification', state.form.notify_via_sms)" />
                  </div>
                </div>

                <div class="flex items-center justify-between">
                  <div class="flex flex-col">
                    <p class="text-big-stone" id="privacy-option-1-label" :class="{ 'font-semibold': state.form.notify_via_email, 'font-regular': !state.form.notify_via_email }">
                      Notify via email
                    </p>
                  </div>
                  <div class="flex justify-end">
                    <toggle v-model="state.form.notify_via_email" @change="save('Via email', 'Notification', state.form.notify_via_email)" />
                  </div>
                </div>

                <div class="" :class="{ '': state.form.notify_via_pushover }">
                  <div class="flex items-center justify-between">
                    <div class="flex flex-col">
                      <p class="text-big-stone" id="privacy-option-1-label" :class="{ 'font-semibold': state.form.notify_via_pushover, 'font-regular': !state.form.notify_via_pushover }">
                      Notify via Pushover
                      </p>
                      <classic-button
                        v-if="state.form.notify_via_pushover && state.form.pushover_user_key"
                        class="mt-1"
                        @click="resetPushover"
                        :isLoading="state.isResettingPushover"
                      >
                        Reset Pushover Configuration
                      </classic-button>
                    </div>
                    <div class="flex justify-end">
                      <toggle v-model="state.form.notify_via_pushover" @change="save('Via Pushover', 'Notification', state.form.notify_via_pushover)" />
                    </div>
                  </div>
                </div>

                <div class="" :class="{ '': state.form.quiet_hours_enabled }">
                  <div class="flex items-center justify-between">
                    <p class="text-big-stone" id="privacy-option-1-label" :class="{ 'font-semibold': state.form.quiet_hours_enabled, 'font-regular': !state.form.quiet_hours_enabled }">
                      Quiet Hours
                    </p>
                    <div class="flex justify-end">
                      <toggle v-model="state.form.quiet_hours_enabled" @change="save('Quiet Hours', 'Quiet Hours', state.form.quiet_hours_enabled)" />
                    </div>
                  </div>
                  <form v-if="state.form.quiet_hours_enabled" @submit.prevent="save('Quiet hours', 'Quiet hours', true)" class="flex items-end space-x-2 md:space-x-4 mt-2 p-2 bg-gray-100">
                    <div class="w-1/2">
                      <time-picker label="Starts At" v-model="state.form.quiet_hours_start" />
                    </div>
                    <div class="w-1/2">
                      <time-picker label="Ends At" v-model="state.form.quiet_hours_end" />
                    </div>
                    <submit-button :is-loading="state.isLoading">Save</submit-button>
                  </form>
                </div>
              </div>
            </div>

            <div class="w-full lg:w-7/12 pt-4 xl:pt-6">
              <div class="">
                <div class="flex">
                  <div class="w-2/5"></div>
                  <div class="flex-1 flex space-x-4 items-center justify-end">
                    <div class="w-1/3 text-xs leading-tight md:text-sm md:leading-tight text-center">Notify When Triggered</div>
                    <div class="w-1/3 text-xs leading-tight md:text-sm md:leading-tight text-center">Notify When Resolved</div>
                    <div v-if="state.form.quiet_hours_enabled" class="w-1/3 text-xs leading-tight md:text-sm md:leading-tight text-center">Notify During Quiet Hours</div>
                  </div>
                </div>
                <ul class="divide-y divide-gray-50">
                  <li v-for="alert in state.form.alerts" :key="alert.alert_id" class="py-4 flex items-center justify-between hover:bg-gray-50 hover:bg-opacity-10 rounded-md">
                    <div class="flex flex-col w-2/5">
                      <p class="text-sm leading-tight text-big-stone pl-2" id="privacy-option-1-label">
                        {{ alert.name }}
                      </p>
                    </div>
                    <div class="flex flex-1 space-x-4 items-center justify-end">
                      <div class="w-1/3 text-center">
                        <toggle v-model="alert.notify_when_triggered" @change="save('Notify when triggered', alert.name, alert.notify_when_triggered)" />
                      </div>
                      <div class="w-1/3 text-center">
                        <toggle v-model="alert.notify_when_resolved" @change="save('Notify when resolved', alert.name, alert.notify_when_resolved)" />
                      </div>
                      <div class="w-1/3 text-center" v-if="state.form.quiet_hours_enabled">
                        <toggle v-model="alert.notify_during_quiet_hours" @change="save('Notify during quiet hours', alert.name, alert.notify_during_quiet_hours)" />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </form-layout>
</template>

<script>
import { reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import http from '@/services/http.js'
import date from '@/helpers/date.js'
import notification from '@/services/notifications.js'
import FormLayout from '@/components/form/Layout.vue'
import Toggle from '@/components/form/Toggle.vue'
import TimePicker from '@/components/form/TimePicker.vue'
import TextInput from '@/components/form/Text.vue'
import Errors from '@/components/form/Errors.vue'
import SubmitButton from '@/components/form/SubmitButton.vue'
import ClassicButton from '@/components/form/Button.vue'

export default {
  name: 'profile-alerts-form',

  components: {
    FormLayout,
    Errors,
    Toggle,
    TimePicker,
    TextInput,
    SubmitButton,
    ClassicButton,
  },

  setup() {
    const router = useRouter()
    const route = useRoute()
    const state = reactive({
      form: {},
      initialData: {},
      errors: [],
      isLoading: false,
      isInitialLoading: true,
      isResettingPushover: false,
      newPhoneNumber: null,
    })

    const getAlerts = async () => {
      const profile = await http.get('user/profile')
      const { data } = await http.get('user/alerts')

      state.form = {
        ...data,
        sms_enabled: profile.data.sms_enabled,
        quiet_hours_start: date.alertsTimeFormat(data.quiet_hours_start).fromApi(),
        quiet_hours_end: date.alertsTimeFormat(data.quiet_hours_end).fromApi(),
      }
      state.initialData = JSON.parse(JSON.stringify(state.form))
      state.isInitialLoading = false

      queryListener()
    }

    getAlerts()

    const save = async (type, alertLabel, isAlertEnabled) => {
      state.errors = []

      state.isLoading = true
      try {
        const phoneNumber = state.form.notify_via_sms ? (state.newPhoneNumber || state.form.phone_number) : null
        await http.post('user/alerts', {
          ...state.form,
          quiet_hours_start: date.alertsTimeFormat(state.form.quiet_hours_start).forApi(),
          quiet_hours_end: date.alertsTimeFormat(state.form.quiet_hours_end).forApi(),
          phone_number: phoneNumber,
        })

        state.errors = []
        notification.success(alertLabel, `<strong>${type}</strong> have been <strong>${isAlertEnabled ? 'enabled' : 'disabled'}</strong>`, null)
        state.initialData = JSON.parse(JSON.stringify(state.form))
        state.initialData.phone_number = phoneNumber
      } catch (e) {
        state.form = JSON.parse(JSON.stringify(state.initialData))

        if (e.response) {
          if (e.response.data.Message.toLowerCase().includes('failed to validate pushover')) {
            notification.info('Redirecting to Pushover', '', false)
            setTimeout(redirectToPushover, 500)
          }
          else {
            notification.error('Error', e.response.data.Message, false)
            state.error = e.response.data.Message
          }
        } else {
          notification.error('Error', 'Request failed', false)
        }
      }

      state.isLoading = false
    }

    const redirectToPushover = () => {
      const hostname = window.location.hostname
      const settingsRootUrl = `https://${hostname}/settings/profile`

      let url = state.form.pushover_subscription_url
      url += '?success=' + encodeURIComponent(settingsRootUrl)
      url += '&failure=' + encodeURIComponent(`${settingsRootUrl}?pushover_failed=true`)
      window.location.href = url
    }

    const queryListener = async () => {
      if (route.query.pushover_failed) {
        notification.error('Pushover Error', 'Pushover configuration failed', null)
      }
      else if (route.query.pushover_unsubscribed) {
        state.form.notify_via_pushover = false
        state.form.pushover_user_key = null
        save('Pushover Subscription', 'Pushover', false)
        router.replace({ query: null })
      }
      else if (route.query.pushover_user_key) {
        state.form.notify_via_pushover = true
        state.form.pushover_user_key = route.query.pushover_user_key
        save('Pushover Subscription', 'Pushover', true)
        router.replace({ query: null })
      }
    }

    const resetPushover = async () => {
      state.isResettingPushover = true
      try {
        const { data } = await http.post(`/user/alerts/reset-pushover`)
        notification.success('Success', 'Pushover Configuration is reset')
        state.form.notify_via_pushover = data.notify_via_pushover
        state.form.pushover_user_key = data.pushover_user_key
      } catch (e) {
        notification.error('Error', e.response?.data?.Message || 'Something went wrong. Please, try again.')
      }
      state.isResettingPushover = false
    }

    return {
      save,
      state,
      resetPushover,
    }
  }
}
</script>
